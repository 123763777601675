<template>
  <v-container>
    <div>
      <v-row class="search">
        <v-col sm="4">
          <label v-html="$t('product_groups_title')"></label>
          <v-text-field name="name" v-model="term.nameDe" @blur="search" />
        </v-col>
        <v-col sm="4">
          <label v-html="$t('product_groups_pos')"></label>
          <v-text-field name="pos" v-model="term.pos" @blur="search" />
        </v-col>
      </v-row>
    </div>
    <div>
      <v-data-table-server
        ref="table"
        striped
        hover
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :headers="fields"
        current-page="query.page"
        :items-per-page="query.size"
        @sort-changed="sortChanged"
        ><template v-slot:[`item.id`]="{ item }">
          <template v-if="item.id">
            <router-link :to="{ name: 'productGroups_write', params: { id: item.id } }" class="tableaction link">
              {{ item.id }}
            </router-link>
          </template>
        </template></v-data-table-server
      >
    </div>
    <v-dialog v-model="dialog" persistent max-width="600px" id="modal-footer-sm">
      <template v-slot:activator="{ props }">
        <v-btn color="primary" v-bind="props">
          <span v-html="$t('product_groups_dialogue_title')" />
        </v-btn>
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title>
            <span class="text-h5" v-html="$t('translations_dialog_title')"></span>
          </v-card-title>
          <v-container>
            <FormJsonSchema ref="formJsonSchema" v-model:model="model" :schema="schema" domainmodel="productGroups" @submit="submit" @reset="reset" />
          </v-container>
          <v-card-actions class="justify-end">
            <v-btn size="sm" @click="dialog.value = false">
              <span v-html="$t('product_groups_button_cancel')" />
            </v-btn>
            <v-btn
              size="sm"
              color="primary"
              @click="
                () => {
                  submit
                  dialog.value = false
                }
              "
            >
              <span v-html="$t('product_groups_button_save')" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <span class="m-2">
      <v-btn id="exporter" color="secondary" @click="exportTableToCSV">
        <span v-html="$t('processing_form_form')" />
      </v-btn>
    </span>
  </v-container>
</template>
<script lang="ts">
import FormJsonSchema from '../../FormJsonSchema'
import schema from './productGroupsSchema.json'
import { apiURL } from '../../../main'
import * as XLSX from 'xlsx/xlsx.mjs'
import { Term } from '../../../services/term'
import _ from 'lodash'

const defaultData = {
  modification: null,
  modifierId: 0,
  creation: null,
  creatorId: 0,
  nameDe: 'Gruppe1',
  nameFr: 'Groupe',
  nameIt: 'Gruppo',
  guid: 'C941683E-0782-11D3-B2DE-009027307A23',
  rootId: 0,
  parentId: null,
  pos: 0,
  ident: 0,
  validUntil: null
}
import BaseMixin from '../../../utils/BaseMixin'
import { showError } from '@/services/axios'
// const searchQuery = { term: { name: "" } };
import { DTSHeader } from '@/services/BackendService'

//https://www.npmjs.com/package/vue-form-schema
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'productGroups',
  data() {
    return {
      apiURL: apiURL + '/productGroups/findAll?term',
      createURL: apiURL + '/productGroups/create',
      items: [],
      schema: schema,
      allItems: [],
      totalElements: 0,
      fields: <DTSHeader[]>[
        {
          title: this.$t('product_groups_table_id'),
          key: 'id',
          sortable: true,
          align: 'left'
        },
        {
          title: this.$t('product_groups_table_header_ident'),
          key: 'ident',
          align: 'left',
          sortable: true
        },
        {
          title: this.$t('product_groups_table_header_name_de'),
          key: 'nameDe',
          sortable: true
        },
        {
          title: this.$t('product_groups_table_header_name_fr'),
          key: 'nameFr',
          sortable: true
        },
        {
          title: this.$t('product_groups_table_header_name_it'),
          key: 'nameIt',
          sortable: true
        }
      ],
      term: { nameDe: '', pos: '' },
      model: defaultData
    }
  },
  mixins: [BaseMixin],
  methods: {
    findByValues(collection, property, values) {
      return _.filter(collection, function (item) {
        return _.has(values, item[property])
      })
    },
    openModal() {
      this.$refs.myModal.show()
    },
    async loadAllItems() {
      try {
        const result = await this.axios.get(
          this.apiURL,
          {
            params: {
              size: this.totalElements
            }
          },
          { ...this.model },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data.content
        }
      } catch (e) {
        showError(e)
      }
    },
    exportTableToCSV() {
      // https://docs.sheetjs.com/
      var workbook = XLSX.utils.book_new()
      var worksheet_name = 'SheetJS'
      var worksheet = XLSX.utils.json_to_sheet(this.allItems)
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheet_name)
      XLSX.writeFile(workbook, 'produktgruppen.xlsx')
    },
    async submit(e) {
      e.preventDefault()
      try {
        const result = await this.axios.post(this.createURL, { ...this.model }, { headers: { 'Content-Type': 'application/json' } })
      } catch (err) {
        showError(err)
      }
    },
    reset() {
      //hook
    },
    filterAPIFieldsX(jsonSchema) {
      // delete jsonSchema.properties.id;
      delete jsonSchema.properties.modification
      delete jsonSchema.properties.modifierId
      delete jsonSchema.properties.creation
      delete jsonSchema.properties.creatorId
      delete jsonSchema.properties.parent_guid
      delete jsonSchema.properties.hyid
      delete jsonSchema.properties.id
      return jsonSchema
    }
  },
  components: { FormJsonSchema },
  mounted() {
    this.schema = this.filterAPIFieldsX(schema)
    // this.$refs.formSchema.load(this.schema);

    this.$nextTick(async () => {
      await this.load()
      this.allItems = await this.loadAllItems()
    })
  },
  watch: {
    term: {
      handler() {
        this.query = _.omitBy(this.query, (v) => _.isUndefined(v) || _.isNull(v) || v === '')
        this.query.term = _.omitBy(this.query.term, (v) => _.isUndefined(v) || _.isNull(v) || v === '')
        this.query.term = Term.stringify_old(this.term)
        this.$router.push({ name: 'productGroups_read', query: this.query }).catch((e) => {
          /* duplicated route */
        })
      },
      deep: true
    }
  }
})
</script>
